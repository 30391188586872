import { Component, OnInit } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './webapp.component.html',
  styleUrls: ['./webapp.component.scss']
})
export class WebappComponent implements OnInit {

  constructor(
      private $router: Router
  ) {
      this.$router.events.subscribe((event: any) => {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
          // if (event instanceof NavigationEnd && event.urlAfterRedirects === '/quedateencasa') {
          // }
      });
  }

  ngOnInit() {
  }

}
