import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FacebookModule} from 'ngx-facebook';

import { AppRoutingModule } from './app-routing.module';
import { WebappComponent } from './components/webapp/webapp.component';
import { HomeComponent } from './components/home/home.component';
import { PolicyComponent } from './components/policy/policy.component';

@NgModule({
    declarations: [
        WebappComponent,
        HomeComponent,
        PolicyComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FacebookModule.forRoot()
    ],
    providers: [],
    bootstrap: [WebappComponent]
})
export class AppModule { }
