import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {TimelineMax, TweenMax} from 'gsap';
import * as ScrollMagic from 'scrollmagic/scrollmagic/minified/ScrollMagic.min.js';
// import 'scrollmagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';
import {FacebookService, InitParams} from 'ngx-facebook';
import * as MobileDetect from 'mobile-detect';

declare var ScrollMagic: any;


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    private controller = new ScrollMagic.Controller;
    private heightWindow: number;
    private widthWindow: number;
    private mobile: string = null;
    @ViewChild('slide', {static: true}) slide: ElementRef;

    constructor(
        private facebookSrv: FacebookService,
        private renderer: Renderer2
    ) {
        this.mobile = new MobileDetect(window.navigator.userAgent).mobile();
    }


    ngOnInit(): void {
        this.initFacebookService()

        this.heightWindow = window.innerHeight;
        this.widthWindow = window.innerWidth;

        this.setAnimationScroll();

        let counter = 33.33
        if(this.widthWindow < 900) {
            counter = 50
        }

        let slice = 0
        setInterval(() => {
            slice++;

            if(slice >= 6) {
                this.renderer.setStyle(this.slide.nativeElement, 'transition', 'none')
                slice = 0
                this.renderer.setStyle(this.slide.nativeElement, 'transform', `translateX(0)`)
            } else {
                this.renderer.setStyle(this.slide.nativeElement, 'transition', '1s all')
                this.renderer.setStyle(this.slide.nativeElement, 'transform', `translateX(-${slice * counter}%)`)
            }
        },3000)
    }

    private initFacebookService(): void {
        const initParams: InitParams = {xfbml: true, version: 'v3.2'};
        this.facebookSrv.init(initParams)
    }

    private setAnimationScroll() {

        // Home
        new ScrollMagic.Scene({
            triggerElement: ".home",
            triggerHook: "onLeave",
            duration: this.heightWindow
        })
            .setTween(
                new TimelineMax()
                    .to('.one .overlead', 1, {opacity: 0})
                    .to('.one .logo', 1, {opacity: 0})
            )
            .setPin(".home")
            // .addIndicators()
            .addTo(this.controller)

        let targets = [
            '.two .title',
            '.four .title',
            '.six .title',
            '.eight .title'
        ]
        targets.map(target => {
            new ScrollMagic.Scene({
                triggerElement: target,
                triggerHook: "onEnter",
                duration: 200
            })
                .setTween(
                    TweenMax.from(target, 1, {transform: 'translateX(-500px)'})
                )
                // .addIndicators()
                .addTo(this.controller)
        })

        targets = [
            '.two .description',
            '.four .description',
            '.six .description',
            '.eight .description'
        ]
        targets.map(target => {
            new ScrollMagic.Scene({
                triggerElement: target,
                triggerHook: "onEnter",
                duration: 200
            })
                .setTween(
                    TweenMax.from(target, 1, {transform: 'translateX(500px)'})
                )
                // .addIndicators()
                .addTo(this.controller)
        })
    }

}
